import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./Appmedia.css";
import Loader from "./Components/Loader";
import Privacy from "./Pages/Privacy";
//
const Home = lazy(() => import("./Pages/Home"));
const About = lazy(() => import("./Pages/About"));
const Contact = lazy(() => import("./Pages/Contact"));
const Services = lazy(() => import("./Pages/Services"));
//

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/about" element={<About />} exact />
          <Route path="/contact" element={<Contact />} exact />
          <Route path="/services" element={<Services />} exact />
          <Route path="/privacy-policy" element={<Privacy />} exact />
          <Route path="/privacypolicy" element={<Privacy />} exact />
          <Route path="/app-privacy-policy" element={<Privacy />} exact />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
