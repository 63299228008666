import React from "react";
import Logo from "../Images/cbclogo.png";

export default function Loader() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img alt="Logo"width={500} src={Logo} />
      Loading...!
    </div>
  );
}
